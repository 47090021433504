.card_day{
    background-blend-mode:color-burn;
    border-width:1rem;    
    margin-bottom: 1rem;
    display: flex;
}
.text_fecha{
    color:navy;
    border-width:1rem;    
    margin-bottom: 1rem;

}