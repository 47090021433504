.card_day{
    background-blend-mode:color-burn;
    border-width:1rem;    
    margin-bottom: 1rem;
    display: block;
}
.text_titulo{
    color:navy;
    font-weight: bold;
}
.card_main{
    background-color:#f5f5f5;
}